export const NOT_FOUND = "/404";
export const SERVER_ERROR = "/500";
export const UNAUTHORIZED = "/401";

export const AUTH = "/auth";
export const ADMIN = "/admin";
export const MANAGER = "/manager";
export const USER = "/user";

export const RESET_PASSWORD = "/reset-password";

// auth paths
// user pages
export const HOME = "/user/home";
export const DEAL_FLOW = "/user/dealflow";
export const MEMBERS = "/user/memebers";
export const SYNDICATES = "/user/syndicates";
export const SETTINGS = "/user/settings";
export const BIO = "/user/bio";

// manager pages
export const MANAGER_HOME = "/manager/home";