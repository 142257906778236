export const authRequest = {
  LOGIN: '/user/login',
  SIGN_UP: '/user/network/signup',
  FORM: '/form-data',
  ADMIN_SIGN_UP: '/user/network/admin-signup',
  FORGOT_PASSWORD: '/user/network/forgot-password',
  STARTUP_SIGNUP_FORM: '/founder/form-data/get-signup-questions',
  RESET_PASSWORD: '/user/network/reset-password',
  VERIFICATION_CODE: '/user/send-code?email=',
  ME: '/user/me',
  STARTUP_SIGNUP: '/founder/signup',
};

export const emailRequest = {
  subscribe: '/subscribe',
};

export const networkRequest = {
  NETWORK: '/network',
};

export default {
  auth: authRequest,
  network: networkRequest,
};
