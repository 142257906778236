import { css } from '@emotion/core';
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import MuliRegular from 'assets/fonts/Muli/Muli-Regular.ttf';
import MuliSemiBold from 'assets/fonts/Muli/Muli-SemiBold.ttf';
import MuliExtraBold from 'assets/fonts/Muli/Muli-ExtraBold.ttf';
import MuliBold from 'assets/fonts/Muli/Muli-Bold.ttf';
import Ubuntu from 'assets/fonts/Ubuntu/Ubuntu-R.ttf';
import UbuntuBold from 'assets/fonts/Ubuntu/Ubuntu-Bold.ttf';

export default css`
 @font-face {
    font-family: Muli;
    src: url(${MuliRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
}
 @font-face {
    font-family: Muli-SemiBold;
    src: url(${MuliSemiBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
}
 @font-face {
    font-family: Muli-Bold;
    src: url(${MuliBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
}
 @font-face {
    font-family: Muli-ExtraBold;
    src: url(${MuliExtraBold}) format('truetype');
    font-weight: bold;
    font-style: bold;
}
 @font-face {
    font-family: Ubuntu;
    src: url(${Ubuntu}) format('truetype');
    font-weight: normal;
    font-style: normal;
}
 @font-face {
    font-family: Ubuntu-Bold;
    src: url(${UbuntuBold}) format('truetype');
    font-weight: bold;
    font-style: bold;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
a {
  color: inherit;
  text-decoration: none;
}

html,
body {
  font-family: Muli, 'Fira Sans', sans-serif;
  box-sizing: border-box;
}

body .muli {
  font-family: Muli, 'Fira Sans', sans-serif;
}

body .header {
  font-family: Ubuntu, 'Fira Sans', sans-serif;
}
`;



















