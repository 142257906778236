import { theme } from '@chakra-ui/core'
export interface IHeadingColor {
    blue: string,
    grey: string
}
interface IGreyColor {
    lighter: string
    light: string
    dark: string
    lightest: string
}

interface ISecondaryColor {
    light: string
    dark: string
}
export type ColorType = {
    inherit: string
    white: string
    black: string
    success: string
    primary: string
    error: string
    transparent: string
    secondary: ISecondaryColor
    grey: IGreyColor
    heading: IHeadingColor
    footer: string
}

// import other fonts here
export type ColorProps = keyof ColorType

export interface AppTheme {
    colors: ColorType
}

const appColors: ColorType = {
	inherit: 'inherit',
    white: '#FFFFFF',
    black: '#000000',
    primary: '#0566AD',
    error: '#E53E3E',
    success: '#28a745',
    transparent: 'transparent',
    secondary: {
        dark: '#5CABE6',
        light: '#99D4FF'
    },
    grey: {
        dark: '#575757',
        light: '#707070',
        lighter: '#D5D5D5',
        lightest: '#9F9F9F',
    },
    /** 
     *  Use this color for headings
     * To access this color anywhere in the app use the getColor function as follows
     *  getColor('heading.primary') 
     */
    heading: {
        blue: '#033559', // this is the color to use for blue headings 
        grey: '#9F9F9F' // This is the color to use for grey headings
    },
    footer: '#F5F5F5'
}

export const getColor = (path: string) => findColor(path, appColors)

const findColor = (key: string, colors: ColorType): string => {
    const keys: Array<string> = key.split('.');
    const colorKey: string = keys[0]; // the first key
    let value;

    /*
     * If the current value of colorKey is not a string; i.e; the color value, then it must be a path
     * shift the array pointer to point to the next index and perform a recursive call of the function
     */
    if (keys.length > 1 && typeof colors[colorKey] !== 'string') {
      keys.shift();
      value = findColor(keys.join('.'), colors[colorKey]);
    } else if (keys.length === 1) {
      value = colors[colorKey];
    }

    if (value) return value;

    throw new Error(`${key} is not a valid color path`);
  }

  export const chakraTheme = {
      ...theme,
      shadows: {
          ...theme.shadows,
          navbar: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          inputFocusBoxShadow: '-1px 1px 8px 2px rgba(5, 102, 173, 0.7)',
          greyShadow: '10px 10px 40px #00000029',
          linkShadow: '2px 2px 10px #00000029',
          primaryShadow: '0px 3px 15px #0566AD38'
      }
  }

