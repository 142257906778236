import React from 'react'
import styled from '@emotion/styled';
import { Image } from '@chakra-ui/react';
import Logo from 'assets/images/logo.png';

const Spinner = () => {
  return (
    <Spinner.Wrapper>
      <div className="pluse">
        <Image objectFit='cover' src={Logo} alt='' />
      </div>
    </Spinner.Wrapper>
  )
}
Spinner.Wrapper = styled.section`
display:flex;
align-items:center;
justify-content:center;
height:100vh;
.pluse {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  font-size: 48px;
  position: relative;
  display:flex;
  justify-content: center;
  align-items: center;
}

.pluse:before,
.pluse:after {
  content: '';
  position: absolute;
  left: -20px;
  top: -20px;
  right: -20px;
  bottom: -20px;
  border: 1px solid #7F96E5;
  border-radius: 50%;
  -webkit-animation: pulsing 1.3s linear infinite;
          animation: pulsing 1.3s linear infinite;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.pluse:after {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@-webkit-keyframes pulsing {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

@keyframes pulsing {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
`
export default Spinner
