// @ts-ignore
import { NOT_FOUND } from 'pages/pagesPath';
import * as React from 'react';

const { lazy } = React;

export default [
  {
    path: '/',
    component: lazy(() => import('pages/UnAuthPages/LandingPage')),
    exact: true,
  },
  {
    path: '/home',
    component: lazy(() => import('pages/UnAuthPages/LandingPage')),
    exact: true,
  },
  {
    path: '/contact-us',
    component: lazy(() => import('./ContactUs')),
    exact: true,
  },
  {
    path: '/policies',
    component: lazy(() => import('./Legal/Policy')),
    exact: true,
  },

  {
    path: '/terms-and-conditions',
    component: lazy(() => import('./Legal/Terms')),
    exact: true,
  },
  {
    path: '/about-us',
    component: lazy(() => import('./AboutUs')),
    exact: true,
  },
  {
    path: '/raise-capital',
    component: lazy(() => import('./RaisedCapital')),
    exact: true,
  },
  {
    path: '/business-investors',
    component: lazy(() => import('./BusinessPage')),
    exact: true,
  },
  {
    path: NOT_FOUND,
    component: lazy(() => import('pages/NotFound')),
  },
];
