import { defaultSingleObjectState } from "utils/constants";
import { extractStatus, handleFetch } from 'helpers/reducerHelper';
import * as types from '../types';

const defaultPayload = {
  data: {},
  errors: {},
};
const initalState = {
  subscription: defaultSingleObjectState
}
const emailReducer = (
  state = initalState,
  { type, payload = defaultPayload }: any
) => {
  const status = extractStatus(type);
  switch (type) {
    case types.EMAIL_SUBSCRIBE_REQUEST:
    case types.EMAIL_SUBSCRIBE_SUCCESS:
    case types.EMAIL_SUBSCRIBE_ERROR:
      return handleFetch(state, status, payload, "subscription");
    default:
      return state;
  }
};

export default emailReducer;