import * as types from '../types';
import { defaultManyObjectState } from "utils/constants";
import { extractStatus, handleFetch } from 'helpers/reducerHelper';


const defaultPayload = {
  data: [],
  errors: [],
};
const initalState = {
  ...defaultManyObjectState
}
const networkReducer = (
  state = initalState,
  { type, payload = defaultPayload }: any
) => {
  const status = extractStatus(type);
  switch (type) {
    case types.FETCH_NETWORKS_REQUEST:
    case types.FETCH_NETWORKS_SUCCESS:
    case types.FETCH_NETWORKS_ERROR:
      return handleFetch(state, status, payload);
    default:
      return state;
  }
};

export default networkReducer;