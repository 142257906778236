import { all } from 'redux-saga/effects';
import authSaga from './auth';
import emailSaga from './email';
import networkSaga from './network';

export default function* rootSaga() {
  yield all([
    authSaga(),
    emailSaga(),
    networkSaga()
  ]);
}
