import { defaultSingleObjectState } from 'utils/constants';
import { extractStatus, handleFetch } from 'helpers/reducerHelper';
import * as types from '../types';

const defaultPayload = {
  data: [],
  errors: [],
};
const initalState = {
  ...defaultSingleObjectState,
  signUp: defaultSingleObjectState,
  adminSignUp: defaultSingleObjectState,
  signIn: defaultSingleObjectState,
  forgotPassword: defaultSingleObjectState,
  resetPassword: defaultSingleObjectState,
  verification: defaultSingleObjectState,
  startupFormData: defaultSingleObjectState,
  startupSignUp: defaultSingleObjectState,
};
const authReducer = (
  state = initalState,
  { type, payload = defaultPayload }: any
) => {
  const status = extractStatus(type);
  switch (type) {
    case types.GET_FORM_REQUEST:
    case types.GET_FORM_SUCCESS:
    case types.GET_FORM_ERROR:
      return handleFetch(state, status, payload);
    case types.SIGN_UP_REQUEST:
    case types.SIGN_UP_SUCCESS:
    case types.SIGN_UP_ERROR:
      return handleFetch(state, status, payload, 'signUp');
    case types.STARTUP_SIGNUP_FORM_ERROR:
    case types.STARTUP_SIGNUP_FORM_SUCCESS:
    case types.STARTUP_SIGNUP_FORM_REQUEST:
      return handleFetch(state, status, payload, 'startupFormData');
    case types.SIGN_IN_REQUEST:
    case types.SIGN_IN_SUCCESS:
    case types.SIGN_IN_ERROR:
      return handleFetch(state, status, payload, 'signIn');
    case types.ADMIN_SIGN_UP_REQUEST:
    case types.ADMIN_SIGN_UP_SUCCESS:
    case types.ADMIN_SIGN_UP_ERROR:
      return handleFetch(state, status, payload, 'adminSignUp');
    case types.STARTUP_SIGN_UP_REQUEST:
    case types.STARTUP_SIGN_UP_SUCCESS:
    case types.STARTUP_SIGN_UP_ERROR:
      return handleFetch(state, status, payload, 'startupSignUp');
    case types.FORGOT_PASSWORD_REQUEST:
    case types.FORGOT_PASSWORD_SUCCESS:
    case types.FORGOT_PASSWORD_ERROR:
      return handleFetch(state, status, payload, 'forgotPassword');
    case types.RESET_PASSWORD_REQUEST:
    case types.RESET_PASSWORD_SUCCESS:
    case types.RESET_PASSWORD_ERROR:
      return handleFetch(state, status, payload, 'resetPassword');
    case types.GET_VERIFICATION_CODE_REQUEST:
    case types.GET_VERIFICATION_CODE_SUCCESS:
    case types.GET_VERIFICATION_CODE_ERROR:
      return handleFetch(state, status, payload, 'verification');
    case types.CLEAR_AUTH_MESSAGES:
      const { key } = payload;
      let message = state[key].data.message;
      message = message ? '' : undefined;
      if (key) {
        return {
          ...state,
          [key]: {
            ...state[key],
            message: '',
            errors: {},
            data: {
              ...state[key].data,
              message,
            },
          },
        };
      } else return state;
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        signIn: {
          ...state.signIn,
          success: false,
        },
      };
    case types.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        signIn: {
          ...state.signIn,
          success: true,
        },
      };
    case types.GET_USER_DETAILS_ERROR:
      return {
        ...state,
        signIn: {
          ...state.signIn,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default authReducer;
