import { combineReducers } from 'redux';
import authReducer from './auth';
import emailReducer from './email';
import networkReducer from './network';

export default combineReducers({
  auth: authReducer,
  email: emailReducer,
  network: networkReducer
});
