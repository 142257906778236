export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const GET_FORM_REQUEST = 'GET_FORM_REQUEST';
export const GET_FORM_SUCCESS = 'GET_FORM_SUCCESS';
export const GET_FORM_ERROR = 'GET_FORM_ERROR';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const ADMIN_SIGN_UP_REQUEST = 'ADMIN_SIGN_UP_REQUEST';
export const ADMIN_SIGN_UP_SUCCESS = 'ADMIN_SIGN_UP_SUCCESS';
export const ADMIN_SIGN_UP_ERROR = 'ADMIN_SIGN_UP_ERROR';

export const STARTUP_SIGN_UP_REQUEST = 'STARTUP_SIGN_UP_REQUEST';
export const STARTUP_SIGN_UP_SUCCESS = 'STARTUP_SIGN_UP_SUCCESS';
export const STARTUP_SIGN_UP_ERROR = 'STARTUP_SIGN_UP_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const GET_VERIFICATION_CODE_REQUEST = 'GET_VERIFICATION_CODE_REQUEST';
export const GET_VERIFICATION_CODE_SUCCESS = 'GET_VERIFICATION_CODE_SUCCESS';
export const GET_VERIFICATION_CODE_ERROR = 'GET_VERIFICATION_CODE_ERROR';

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';

export const STARTUP_SIGNUP_FORM_REQUEST = 'STARTUP_SIGNUP_FORM_REQUEST';
export const STARTUP_SIGNUP_FORM_SUCCESS = 'STARTUP_SIGNUP_FORM_SUCCESS';
export const STARTUP_SIGNUP_FORM_ERROR = 'STARTUP_SIGNUP_FORM_ERROR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const CLEAR_AUTH_MESSAGES = 'CLEAR_AUTH_MESSAGES';
