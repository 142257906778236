import { safeSaga } from 'helpers';
import { cookies } from 'lib/cookies';
import { takeLatest, call, put } from 'redux-saga/effects';
import api, { authRequest } from 'services/api';
import * as types from '../types';

function* getForm() {
  const { data } = yield call([api, 'get'], authRequest.FORM);
  yield put({
    type: types.GET_FORM_SUCCESS,
    payload: { data },
  });
}

function* getStartupSignupFormData() {
  const { data } = yield call([api, 'get'], authRequest.STARTUP_SIGNUP_FORM);
  yield put({
    type: types.STARTUP_SIGNUP_FORM_SUCCESS,
    payload: { data },
  });
}

function* signUp({ payload }) {
  const { data } = yield call([api, 'post'], authRequest.SIGN_UP, payload);
  yield put({
    type: types.SIGN_UP_SUCCESS,
    payload: data,
  });
}
function* signIn({ payload: { rememberMe, ...payload } }) {
  const { data } = yield call([api, 'post'], authRequest.LOGIN, payload);
  // store token to local storage
  if (rememberMe) {
    const twoWeeks = new Date(Date.now() + 2 * 7 * 24 * 60 * 60 * 1000);
    const cookieOptions = { path: '/', expires: twoWeeks };
    cookies.set('token', data.token, cookieOptions);
  } else {
    sessionStorage.setItem('token', data.token);
  }
  yield put({
    type: types.SIGN_IN_SUCCESS,
    payload: data,
  });
}
function* forgotPassword({ payload }) {
  const { data } = yield call(
    [api, 'post'],
    authRequest.FORGOT_PASSWORD,
    payload
  );
  yield put({
    type: types.FORGOT_PASSWORD_SUCCESS,
    payload: data,
  });
}
function* resetPassword({ payload }) {
  const { data } = yield call(
    [api, 'post'],
    authRequest.RESET_PASSWORD,
    payload
  );
  yield put({
    type: types.RESET_PASSWORD_SUCCESS,
    payload: data,
  });
}
function* adminSignUp({ payload }) {
  const { data } = yield call(
    [api, 'post'],
    authRequest.ADMIN_SIGN_UP,
    payload
  );
  yield put({
    type: types.ADMIN_SIGN_UP_SUCCESS,
    payload: data,
  });
  // const tomorrow = new Date(Date.now() + (1 * 24 * 60 * 60 * 1000));
  // const cookieOptions = { path: '/', expires: tomorrow };
  // cookies.set('token', data.token, cookieOptions);
}

function* startupSignup({ payload }) {
  const { data } = yield call(
    [api, 'post'],
    authRequest.STARTUP_SIGNUP,
    payload
  );
  yield put({
    type: types.STARTUP_SIGN_UP_SUCCESS,
    payload: data,
  });
}
function* getVerification({ payload }) {
  const data = yield call(
    [api, 'post'],
    authRequest.VERIFICATION_CODE + payload
  );
  yield put({
    type: types.GET_VERIFICATION_CODE_SUCCESS,
    payload: data,
  });
}
function* getUserDetails() {
  try {
    yield call([api, 'get'], authRequest.ME);
    yield put({
      type: types.GET_USER_DETAILS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.GET_USER_DETAILS_ERROR,
    });
  }
}

function* logout() {
  yield cookies.remove('token');
  yield sessionStorage.clear();
}

export default function* authSaga() {
  yield takeLatest(
    types.GET_FORM_REQUEST,
    safeSaga(getForm, types.GET_FORM_ERROR)
  );
  yield takeLatest(
    types.STARTUP_SIGNUP_FORM_REQUEST,
    safeSaga(getStartupSignupFormData, types.STARTUP_SIGNUP_FORM_ERROR)
  );
  yield takeLatest(
    types.SIGN_UP_REQUEST,
    safeSaga(signUp, types.SIGN_UP_ERROR)
  );
  yield takeLatest(
    types.ADMIN_SIGN_UP_REQUEST,
    safeSaga(adminSignUp, types.ADMIN_SIGN_UP_ERROR)
  );
  yield takeLatest(
    types.STARTUP_SIGN_UP_REQUEST,
    safeSaga(startupSignup, types.STARTUP_SIGN_UP_ERROR)
  );
  yield takeLatest(
    types.SIGN_IN_REQUEST,
    safeSaga(signIn, types.SIGN_IN_ERROR)
  );
  yield takeLatest(
    types.FORGOT_PASSWORD_REQUEST,
    safeSaga(forgotPassword, types.FORGOT_PASSWORD_ERROR)
  );
  yield takeLatest(
    types.RESET_PASSWORD_REQUEST,
    safeSaga(resetPassword, types.RESET_PASSWORD_ERROR)
  );
  yield takeLatest(
    types.GET_VERIFICATION_CODE_REQUEST,
    safeSaga(getVerification, types.GET_VERIFICATION_CODE_ERROR)
  );
  yield takeLatest(types.GET_USER_DETAILS_REQUEST, getUserDetails);
  yield takeLatest(types.LOGOUT_REQUEST, safeSaga(logout));
}
