import axios from 'axios';
import { cookies } from 'lib/cookies';

export * from './request';

const MASTER = 'master';
export const baseURL =
  process.env.REACT_APP_BRANCH === MASTER
    ? 'https://conectivest-mvp-live.herokuapp.com/api'
    : 'https://conectivest-mvp.herokuapp.com/api/';

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json ',
  },
});

//  This adds a token before all the requests.
// https://stackoverflow.com/questions/57251719/acquiring-a-new-token-with-axios-interceptors
const addTokenToRequest = (request: any) => {
  request.headers.Authorization = `Bearer ${
    cookies.get('token') || sessionStorage.getItem('token')
  }`;
  return request;
};

axiosInstance.interceptors.request.use(addTokenToRequest);

export default axiosInstance;
