import { put } from "redux-saga/effects";

export const SET_SAGA_ERROR = "SET_SAGA_ERROR";

export default function safeSaga(func: any, action?: any) {
  return function* (args: any) {
    try {
      yield* func(args);
    } catch (err) {
      let errors;
      const tempError = yield err.response.data.error;
      if (tempError) {
        errors = tempError
      } else {
        errors = yield err.response.data.message;
      }
      if (action) {
        yield put({
          type: action,
          payload: { errors },
        });
      } else {
        yield put({
          type: SET_SAGA_ERROR,
          payload: { errors },
        });
      }
    }
  };
}
