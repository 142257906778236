const commonState = {
  processing: false,
  processed: false,
  success: false,
  errors: {},
  message: '',
};
export const defaultSingleObjectState: SingleObjectState = {
  ...commonState,
  data: {},
};

export const defaultManyObjectState = {
  ...commonState,
  data: [],
  pagination: {
    currentPage: '',
    nextPage: '',
    totalEntries: 0,
  },
};
export const ADMIN_LIVE_URL = 'https://angel.conectivest.com/app';
export const APP_LIVE_URL = 'https://home.conectivest.com/';
export const ADMIN_DASHBOARD_URL =
  'https://angel.conectivest.com/app/pages/user-profile';
