import { safeSaga } from "helpers";
import { call, put, takeLatest } from "redux-saga/effects";
import api, { networkRequest } from 'services/api';
import * as types from '../types';


function* getNetworks() {
  const { data } = yield call([api, 'get'], networkRequest.NETWORK);
  yield put({
    type: types.FETCH_NETWORKS_SUCCESS,
    payload: { data },
  });
};

export default function* networkSaga() {
  yield takeLatest(types.FETCH_NETWORKS_REQUEST, safeSaga(getNetworks, types.FETCH_NETWORKS_ERROR));
}
