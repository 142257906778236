import routes from './UnAuthPages/routes';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NOT_FOUND } from 'pages/pagesPath';
import ErrorBoundary from 'pages/ErrorBoundary';
import Spinner from 'components/Spinner';
import { Center } from '@chakra-ui/react';

const Pages = () => (
  <ErrorBoundary>
    <React.Suspense
      fallback={
        <Center>
          <Spinner />
        </Center>
      }
    >
      <Switch>
        {routes.map(({ component, path, exact }, index) => (
          <Route key={index} component={component} path={path} exact={exact} />
        ))}
        <Redirect exact from='/' to='/home' />
        <Redirect to={NOT_FOUND} />
      </Switch>
    </React.Suspense>
  </ErrorBoundary>
);
export default Pages;
