import React from 'react';
import { Global } from '@emotion/core';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import __globalCss from 'styles/__global.css';
import store from 'redux/store';
import Pages from 'pages';
import {
  ThemeProvider as ChakraThemeProvider,
  CSSReset,
} from '@chakra-ui/core';
import { chakraTheme } from 'lib/theme';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider } from 'react-cookie';
import ScrollToTop from 'utils/ScrollTop';

const App = () => {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <Router>
          <ScrollToTop>
            <Global styles={__globalCss} />
            <ToastContainer />
            <ChakraThemeProvider theme={chakraTheme}>
              <CSSReset />
                <Pages />
            </ChakraThemeProvider>
          </ScrollToTop>
        </Router>
      </Provider>
    </CookiesProvider>
  );
};

export default App;
