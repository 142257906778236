import { safeSaga } from "helpers";
import { call, put, takeLatest } from "redux-saga/effects";
import api, { emailRequest } from 'services/api';
import * as types from '../types';


function* subscribeToEmailList({ payload }) {
  const { data } = yield call([api, 'post'], emailRequest.subscribe, payload);
  yield put({
    type: types.EMAIL_SUBSCRIBE_SUCCESS,
    payload: { data },
  });
};

export default function* emailSaga() {
  yield takeLatest(types.EMAIL_SUBSCRIBE_REQUEST, safeSaga(subscribeToEmailList, types.EMAIL_SUBSCRIBE_ERROR));
}
